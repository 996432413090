import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'reactstrap';

import Layout from '../components/Layout';
import Image from '../components/Image';


function Menu({ data }) {
  const meta = [{
    name: 'description',
    content: 'The Subway menu offers a wide range of sub sandwiches, ' + 
             'salads and breakfast ideas for every taste. View the ' +
             'abundant options on the Subway menu and discover ' +
             'better-for-you meals!'
  }];

  return (
    <Layout title="Menu" meta={meta}>
      <Container className="px-0" fluid>
        <Container className="px-0">
          <Image
            sm={data.sm.size.fluid.src}
            md={data.md.size.fluid.src}
            className="w-100"
          />
        </Container>
      </Container>
    </Layout>
  );
}

export const data = graphql`
  {
    md:file(relativePath: { eq: "desktop-menu-3.jpg" }) {
      size:childImageSharp {
        fluid(maxWidth: 1100, toFormat: PNG, pngQuality: 90) {
          src
        }
      }
    }
    sm:file(relativePath: { eq: "mobile-menu-3.jpg" }) {
      size:childImageSharp {
        fluid(maxWidth: 640, toFormat: PNG, pngQuality: 90) {
          src
        }
      }
    }
  }
`;

export default Menu;
